import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { VehicleStyle } from "../../style";

export default function VehicleSelection({ precart }) {
    const style = VehicleStyle()
    
    const getYears = async() => {
        const apiAddress = process.env.REACT_APP_API_SERVER + '/web/vehicles/'
        return await fetch(apiAddress, { method: "GET", headers: { authentication: process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) }})
            .then((response) => response.json())
    }
    
    const years = useQuery({
        queryKey: ["years"],
        queryFn: getYears
    })

    const getMakes = async() => {
        const apiAddress = process.env.REACT_APP_API_SERVER + '/web/vehicles/' + precart.vehicle.year
        return await fetch(apiAddress, { headers: { authentication: process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) }})
            .then((response) => response.json())
    }
    
    const makes = useQuery({
        queryKey: ["makes", precart.vehicle.year],
        queryFn: getMakes,
        enabled: !!precart.vehicle.year
    })

    const getModels = async() => {
        const apiAddress = process.env.REACT_APP_API_SERVER + '/web/vehicles/' + precart.vehicle.year + '/' + precart.vehicle.make
        return await fetch(apiAddress, { headers: { authentication: process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) }})
            .then((response) => response.json())
    }
    
    const models = useQuery({
        queryKey: ["models", precart.vehicle.year, precart.vehicle.make],
        queryFn: getModels,
        enabled: !!precart.vehicle.make
    })

    const handleYear = (selectedOption) => {
        precart.setVehicle({year: selectedOption.value, make: null, model: null})
    }

    const handleMake = (selectedOption) => {
        precart.setVehicle({...precart.vehicle, make: selectedOption.value, model: null})
    }

    const handleModel = (selectedOption) => {
        precart.setVehicle({...precart.vehicle, model: selectedOption.value})
    }

    const scrollToVehicle = () => {
        if(window.innerWidth < 780) {
            setTimeout(() => {
                const yOffset = -40; 
                const element = document.getElementById('vehicle-selection');
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

                window.scrollTo({top: y, behavior: 'smooth'});
                
                
                /*document.querySelector('#vehicle-selection').scrollIntoView({ 
                    behavior: 'smooth' 
                });*/
            }, 100)
        }
    }
    
    return (
        years && <div style={style.container} id="vehicle-selection">
            <div style={style.title}>Select Vehicle</div>
            <div style={style.yearMakeContainer}>
                <Select 
                    styles={{
                        container: (base) => ({...base, ...style.year}),
                        control: (base) => ({...base, ...style.item })
                    }}
                    value={precart.vehicle.year ? {label: precart.vehicle.year, value: precart.vehicle.year} : ''}
                    options={years.data ? years.data.payload : []}
                    onChange={handleYear}
                    onFocus={scrollToVehicle}
                    isSearchable={false}
                    id="select-year"
                    placeholder="Year"
                    aria-label="Select a Year"
                />
                <Select 
                    styles={{
                        container: (base) => ({...base, ...style.make}),
                        control: (base) => ({...base, ...style.item })
                    }}
                    value={precart.vehicle.make ? {label: precart.vehicle.make, value: precart.vehicle.make} : ''}
                    isDisabled={precart.vehicle.year ? false : true}
                    options={makes.data ? makes.data.payload : []}
                    onChange={handleMake}
                    onFocus={scrollToVehicle}
                    isSearchable={false}
                    id="select-make"
                    placeholder="Make"
                    aria-label="Select a Make"
                />
            </div>
            <Select 
                styles={{
                    container: (base) => ({...base, ...style.model}),
                    control: (base) => ({...base, ...style.item }), 
                }}
                value={precart.vehicle.model ? {label: precart.vehicle.model, value: precart.vehicle.model} : ''}
                isDisabled={precart.vehicle.make ? false : true}
                options={models.data ? models.data.payload : []}
                onChange={handleModel}
                onFocus={scrollToVehicle}
                isSearchable={false}
                id="select-model"
                placeholder="Model"
                aria-label="Select a Model"
            />
        </div>
    );
}