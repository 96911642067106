import { useContext } from "react"
import { FontContext } from "../providers/fontProvider";
import { ProductsContext } from "../providers/productsProvider";

export function HeaderStyle(landingData) { 
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme
    
    return {
        border: {
            width: "100vw",
            borderBottom: landingData ? 'none' : `1px solid ${theme.pageText}`,
            display: "flex",
            justifyContent: "center",
            backgroundColor: landingData ? landingData.header.backgroundColor : theme.headerBackground,
            zIndex: "20"
        },
        container: {
            width: "100%",
            maxWidth: "1700px",
            padding: fonts.mobile ? "15px 4vw" : "10px 4vw",
            display: "flex",
            justifySelf: "center",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: landingData ? landingData.header.backgroundColor : theme.headerBackground
        },
        logoContainer: {
            padding: "4px 0",
            cursor: "pointer"
        },
        iconHolder: {
            display: "flex",
            gap: fonts.mobile ? "18px" : "30px",
            position: "relative"
        },
        iconContainer: {
            height: fonts.mobile ? "40px" : "60px",
            width: fonts.mobile ? "40px" : "80px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            cursor: "pointer",
            borderRadius: "4px",
            textDecoration: "none"
        },
        icon: {
            height: fonts.mobile ? "24px" : "32px",
            width: fonts.mobile ? "24px" : "32px",
            stroke: landingData ? landingData.header.textColor : theme.headerText,
            transitionDuration: ".3s",
        },
        iconText: {
            fontSize: "12px",
            display: fonts.mobile ? "none" : "block",
            fontWeight: "600",
            color: landingData ? landingData.header.textColor : theme.headerText,
            transitionDuration: ".3s",
        },
        cart: {
            zIndex: "120"
        },
        cartNumber: {
            position: "absolute",
            top: "0",
            right: "4px",
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: theme.specialBackground,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        cartNumberText: {
            color: theme.specialText,
            fontSize: "11px",
            fontWeight: "500"
        }
    }
}

export function TOCStyle() { 
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme
    
    return {
        container: {
            width: "100%",
            minWidth: "300px",
            maxWidth: "1700px",
            padding: "18px 4vw",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            color: theme.pageText
        },
        link: {
            fontSize: fonts.mobile ? "12px" : "16px",
            fontWeight: "400", 
            lineHeight: "20px",
            color: theme.pageText,
            transitionDuration: ".3s"
        }
    }
}

export function AnnouncementStyle() {
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme

    return {
        container: {
            padding: "8px 36px",
            backgroundColor: theme.errorText,
            color: theme.pageBackground,
            fontSize: fonts.mobile ? "12px" : "16px"
        }
    }
}

export function CartPanelStyle() { 
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme
    
    return {
        container: {
            position: "absolute",
            right: "0",
            top: "0",
            overflow: "hidden",
            whiteSpace: "nowrap",
            zIndex: "100",
            transitionDuration: ".4s",
            backgroundColor: theme.headerBackground,
            paddingTop: fonts.mobile ? "32px" : "40px",
            paddingBottom: fonts.mobile ? "32px" : "40px"
        },
        inner: {
            padding: "0 24px",
            display: "flex",
            gap: "24px",
            flexDirection: "column",
            color: theme.headerText
        },
        title: {
            ...fonts.h3,
        },
        itemContainer: {
            display: "flex",
            gap: fonts.mobile ? "12px" : "16px",
            padding: "24px 0",
            alignItems: "center"
        },
        image: {
            width: fonts.mobile ? "114px" : "164px",
            height: fonts.mobile ? "114px" : "164px",
            backgroundColor: theme.headerText,
        },
        detailsContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: fonts.mobile ? "2px" : "6px"
        },
        name: {
            fontSize: fonts.mobile ? "12px" : "14px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "600"
        },
        details: {
            fontSize: fonts.mobile ? "10px" : "12px",
            lineHeight: fonts.mobile ? "14px" : "20px",
            fontWeight: "500"
        },
        cost: {
            fontSize: fonts.mobile ? "12px" : "14px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "600"
        },
        delete: {
            width: fonts.mobile ? "24px" : "32px",
            height: fonts.mobile ? "24px" : "32px",
            stroke: theme.headerText,
            color: theme.headerText,
            cursor: "pointer",
            transitionDuration: ".3s"
        },
        noItems: {
            ...fonts.standard,
            padding: "12px 0",
        },
        button: {
            ...fonts.h3,
            color: theme.buttonText,
            width: "100%",
            height: fonts.mobile ? "40px" : "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.buttonBackground,
            textDecoration: "none",
            transitionDuration: ".3s"
        }
    }
}

export function FooterStyle() { 
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme
    
    return {
        container: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "60px",
            gap: "40px",
            backgroundColor: theme.footerBackground,
            alignItems: "center"
        },
        inner: {
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            justifyContent: fonts.mobile ? "center" : "space-between",
            gap: fonts.mobile ? "40px" : "80px",
        },
        section: {
            display: "flex",
            flexDirection: "column",
            //minWidth: "150px",
            gap: "8px",
            alignItems: fonts.mobile ? "center" : "flex-start"
        },
        logo: {
            marginBottom: "8px"
        },
        header: {
            ...fonts.h4,
            marginBottom: "8px",
            color: theme.footerText
        },
        link: {
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "18px",
            textDecoration: "none",
            color: theme.footerText,
            transitionDuration: ".2s"
        },
        line: {
            width: "100%",
            height: "1px",
            backgroundColor: theme.footerText
        },
        iconContainer: {
            width: "150px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px"
        },
        iconHolder: {
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
        },
        icon: {
            width: "20px",
            height: "20px",
            fill: theme.footerText,
            transitionDuration: ".3s"
        },
        copyright: {
            width: "100%",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "18px",
            textAlign: "center",
            color: theme.footerText
        }
    }
}