import { useContext, useEffect, useState } from "react"
import { PrecartContext } from "../../providers/precartProvider"
import { FullImageStyle } from "./style"

export function FullScreenImage({ product }) {
    const precart = useContext(PrecartContext)
    const style = FullImageStyle()
    const [image, setImage] = useState(precart.imageSelection)
    const [touchStart, setTouchStart] = useState(null)
    const [touchStartY, setTouchStartY] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const [touchEndY, setTouchEndY] = useState(null)
    const [imageSet, setImageSet] = useState()
    const minSwipeDistance = 50

    useEffect(() => {
        document.body.classList.add("modal-open");
        let imageArray = []
        if(product.options && product.options.length > 0) {
            for(var i = 0; i < product.options[precart.colorOption].images.length; i++) 
                imageArray.push(product.options[precart.colorOption].images[i])
        }
        for(var j = 0; j < product.images.length; j++) imageArray.push(product.images[j])
        setImageSet(imageArray)
    }, [product, precart, setImageSet])

    function closeSpecifications(event) {
        if(event.target.id === 'modal-outer' || event.target.id === 'thex') {
            precart.setImageSelection(image)
            precart.setShowFullImage(false)
            document.body.classList.remove("modal-open");
        }
    }

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchEndY(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
        setTouchStartY(e.targetTouches[0].clientY)
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX)
        setTouchEndY(e.targetTouches[0].clientY)
    }

    const onTouchEnd = () => {
        if(window.innerWidth !== window.screen.width) return
        if(!touchStart || !touchEnd || !touchStartY || !touchEndY) return
        const distance = touchStart - touchEnd
        const yDistance = touchStartY - touchEndY
        const isLeftSwipe = Math.abs(distance) > Math.abs(yDistance) && distance > minSwipeDistance
        const isRightSwipe = Math.abs(distance) > Math.abs(yDistance) && distance < -minSwipeDistance
        const isSwipeUp = Math.abs(yDistance) > Math.abs(distance) && yDistance > minSwipeDistance
        const isSwipeDown = Math.abs(yDistance) > Math.abs(distance) && yDistance < -minSwipeDistance

        if(isSwipeUp) return
        else if(isLeftSwipe) {
            if(image === imageSet.length - 1) setImage(0)
            else setImage(image + 1)
        }
        else if(isRightSwipe) {
            if(image === 0) setImage(imageSet.length - 1)
            else setImage(image - 1)
        }
        else if(isSwipeDown) {
            precart.setImageSelection(image)
            precart.setShowFullImage(false)
            document.body.classList.remove("modal-open");
        }
    }
    
    return(
        imageSet && <div style={style.background}>
            <div style={style.container} id="modal-outer" onClick={closeSpecifications}>
                <div style={style.theX} id="thex" onClick={closeSpecifications}>X</div>
                <div style={style.preview} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                    <img style={style.previewImage} width="auto" height="auto" alt='Fabric Preview' title='Fabric Preview' loading="eager" src={imageSet[image]} />
                </div>
                <div style={style.circleContainer}>
                    { imageSet.map((imageUnused, id) => {
                        return <div key={id} style={id === image ? style.bigCircle : style.littleCircle} />
                    })}
                </div>
            </div>
        </div>
    )
}