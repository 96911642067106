import { useState } from "react";
import Logo from "./components/logo";
import ShoppingCart from "./components/cart";
import ContactUs from "./components/contact";
import Faq from "./components/faq";
import { CartPanel } from "../cartPanel";
import { HeaderStyle } from "../style";
import About from "./components/about";

export function Header({ landingData = null }) {
    const style = HeaderStyle(landingData)
    const [hover, setHover] = useState(false)

    return (
        <div style={style.border}>
            <div style={style.container}>
                <Logo landingData={landingData} />
                <div style={style.iconHolder}>
                    { window.innerWidth > 500 && <About landingData={landingData} />}
                    <Faq landingData={landingData} />
                    <ContactUs landingData={landingData} />
                    <ShoppingCart setHover={setHover} landingData={landingData} />
                </div>
                { !landingData && <CartPanel hover={hover} setHover={setHover} /> }
            </div>
        </div>
    );
}