import { FAQTOC } from "./toc"
import { Questions } from "./questions"
import { FaqStyle } from "../style"

export function FAQ({ faqs }) {
    const style = FaqStyle()
    
    return(
        <div style={style.container}>
            <FAQTOC faqs={faqs} />
            <Questions faqs={faqs} />
        </div>
    )
}