import { THEMES } from "./data/themes.mjs";
import ScrollToTop from "./hooks/scrollToTop";
import Paths from "./paths";
import { useContext, useEffect, useState } from "react";
import { ProductsContext } from "./providers/productsProvider";

export default function AppContainer() {
    const products = useContext(ProductsContext)
    const theme = THEMES[products.config.websiteTheme]
    const [tagsAdded, setTagsAdded] = useState(false)

    useEffect(() => {
            if(!tagsAdded) {
                if(process.env.REACT_APP_API_SERVER !== 'http://192.168.1.11') {
                    for(var i = 0; i < products.config.googleTags.length; i++) {
                        window['dataLayer'] = window['dataLayer'] || []
                        window['dataLayer'].push({'gtm.start': new Date().getTime(), event:'gtm.js'})
    
                        var js = window.document.createElement('script')
                        js.async = true
                        js.src = 'https://www.googletagmanager.com/gtm.js?id=' + products.config.googleTags[i]
                        document.getElementsByTagName("head")[0].appendChild(js)
                    }
                    for(var v = 0; v < products.config.clarityTags.length; v++) {
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", products.config.clarityTags[v]);
                    }
                    var hubSpot = window.document.createElement('script')
                    hubSpot.async = true
                    hubSpot.defer = true
                    hubSpot.src = `https://js-na1.hs-scripts.com/47204819.js`
                    document.getElementsByTagName("head")[0].appendChild(hubSpot)
                }
                var mapJs = window.document.createElement('script')
                mapJs.async = true
                mapJs.src = `https://maps.googleapis.com/maps/api/js?key=${products.config.googleMapKey}&libraries=places&loading=async`
                document.getElementsByTagName("head")[0].appendChild(mapJs)

                if(products.landing.sections.filter((s) => s.type === "shopperApproved")) {
                    var SAjs = window.document.createElement('script'); 
                    SAjs.src = "https://www.shopperapproved.com/widgets/36508/merchant/rotating-widget/default.js?v=1"; 
                    SAjs.type = 'text/javascript'; 
                    document.getElementsByTagName("head")[0].appendChild(SAjs);
                }
                
                setTagsAdded(true)
            }
        }, [tagsAdded, setTagsAdded, products.config, products.landing])
    
    return (
        <div id='app-container' style={{backgroundColor: theme.pageBackground}}>
            <ScrollToTop />
            <Paths />
        </div>
    );
}