import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { StarRating } from "../../../components/stars";
import ImageZoom from "../../../hooks/imageZoom";
import { SwatchStyle } from "../style";
import { FontContext } from "../../../providers/fontProvider";

export function Swatch({ productDetails, specify, config, theme }) {
    const style = SwatchStyle()
    const fonts = useContext(FontContext)
    const [select, setSelect] = useState(0)
    
    return (
        <div style={style.container}>
            <Link to={'./' + productDetails.id + '?a=' + select}>
                { fonts.mobile || productDetails.disableZoomPreview ? 
                    <img
                        src={productDetails.options.length > 0 ? productDetails.options[select].smallPreview : productDetails.images[0]} 
                        width="100%" 
                        height="auto" 
                        alt="Fabric Color"
                    /> : 
                    <ImageZoom 
                        src={productDetails.options && productDetails.options.length > 0 ? productDetails.options[select].images[0] : productDetails.images[0]}
                        srcSmall={productDetails.options && productDetails.options.length > 0 ? productDetails.options[select].smallPreview : productDetails.smallPreview}
                    />
                }
            </Link>
            { productDetails.options && productDetails.options.length > 1 &&
                <div style={style.optionContainer}>
                { productDetails.options.map((opt, id) => {
                    if(window.innerWidth > 500) {
                        if(id < 5 || (id === 5 && productDetails.options.length === 6))
                            return <div style={{...style.optionBorder, borderColor: select === id ? theme.specialBackground : "transparent"}} key={id}>
                                <div style={{...style.option, backgroundImage: `url(${opt.preview})`}} onClick={() => setSelect(id)} />
                                </div>
                        else {
                            if(id === 5 && productDetails.options.length > 6)
                                return <Link to={'./' + productDetails.id + '?a=0'} style={style.optionBorder} key={id}>
                                    <div style={{...style.option, backgroundColor: "rgba(0,0,0,.8)"}} key={id}>
                                        <div style={style.number}>+{productDetails.options.length - 5}</div>
                                    </div>
                                </Link>
                            else return null
                        }
                    }
                    else {
                        if(id < 3 || (id === 3 && productDetails.options.length === 4))
                            return <div style={{...style.optionBorder, borderColor: select === id ? theme.specialBackground : "transparent"}} key={id}>
                                <div style={{...style.option, backgroundImage: `url(${opt.preview})`}} onClick={() => setSelect(id)} />
                                </div>
                        else {
                            if(id === 3 && productDetails.options.length > 4)
                                return <Link to={'./' + productDetails.id + '?a=0'} style={style.optionBorder} key={id}>
                                    <div style={{...style.option, backgroundColor: "rgba(0,0,0,.8)"}} key={id}>
                                        <div style={style.number}>+{productDetails.options.length - 3}</div>
                                    </div>
                                </Link>
                            else return null
                        }
                    }
                })}
            </div> }
            <Link to={'./' + productDetails.id + '?a=' + select} style={{textDecoration: "none"}}>
                <div style={style.name}>{productDetails.name}</div>
                <div style={style.tagline}>{productDetails.tagline}</div>
            </Link>
            <Link to={'./' + productDetails.id + '?a=' + select} style={style.priceContainer}>
                <div>
                    <div style={style.price}>${productDetails.cost * (1 - (config.wholeSiteDiscountRate / 100))}</div>
                    {config.discountRate > 0 && <div style={style.priceOld}>${productDetails.cost}</div>}
                </div>
                <StarRating rating={productDetails.rating} specify={specify} isSmall={true}  invertTheme={true} />
                <div style={style.reviews}>({productDetails.reviews})</div>
            </Link>
            <Link to={'./' + productDetails.id + '?a=' + select} style={style.button} className='secondary-button-hover'>Get Started</Link>
        </div>
    )
}