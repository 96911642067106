import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { Link } from "react-router-dom"
import { ProductsContext } from "../../providers/productsProvider";

export default function Info404() {
    const fonts = useContext(FontContext)
    const products = useContext(ProductsContext)
    const theme = products.theme
    
    const container = {
        width: '100vw',
        height: fonts.mobile ? 'calc(100vh - 82px)' : 'calc(100vh - 92px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const littleText = {
        fontSize: fonts.mobile ? '28px' : '36px',
        fontWeight: '800',
        color: theme.fontColorA
    }

    const bigText = {
        fontSize: fonts.mobile ? '180px' : '240px',
        fontWeight: '800',
        color: theme.subFontColorA,
        opacity: '0.2'
    }

    const button = {
        padding: '16px 28px',
        border: `2px solid ${theme.subFontColorA}`,
        borderRadius: '12px',
        textDecoration: 'none',
        outline: 'none',
        transitionDuration: '.2s',
        fontSize: '18px',
        fontWeight: '400',
        color: theme.subFontColorA
    }
    
    return (
        <div style={container}>
            <div style={littleText}>404 Page Not Found</div>
            <div style={bigText}>404</div>
            <Link style={button} className={"h-text-" + theme.hoverColor} to={'/'}>Return Home</Link>
        </div>
    )
}