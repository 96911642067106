import { useContext } from "react"
import { ProductsContext } from "../../providers/productsProvider"
import { Helmet } from "react-helmet-async";
import AboutUs from "./components/aboutUs";
import { Header } from "../../components/header";
import { TOC } from "../../components/toc";
import { Footer } from "../../components/footer";

export default function AboutUsPage() {
    const products = useContext(ProductsContext)
    
    return (
        <>
            <Helmet>
                <title>{products.config.website} - About Us</title>
                <meta name="description" content={products.config.websiteDescription} />
                <link rel="canonical" href={products.config.websiteURL + '/about'} />
            </Helmet>
            <Header />
            <TOC about />
            <AboutUs products={products} />
            <Footer />
        </>
    );
}