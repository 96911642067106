import React, { createContext, useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { ProductsContext } from './productsProvider';

export const CartContext = createContext({});

export function CartProvider({ children }) {
    const products = useContext(ProductsContext)
    const [items, setItems] = useState([])
    const [subTotal, setSubTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [taxRate, setTaxRate] = useState(0)
    const [discount, setDiscountState] = useState(0)
    const [contactValues, setContactValues] = useState()
    const [shippingValues, setShippingValues] = useState()
    const [hover, setHover] = useState(false)
    const [completedOrder, setCompletedOrder] = useState()

    useEffect(() => {
        if(localStorage.getItem("ddCart")) {
            const items = JSON.parse(localStorage.getItem("ddCart"))
            setItems(items)
            let sub = 0
            for(var i = 0; i < items.length; i++) sub += items[i].price * items[i].quantity
            setSubTotal(sub)
        }
    }, [setItems, setSubTotal])
    
    const addItem = useCallback((item, editID = null) => {
        const prevCart = [...items]
        if(editID || editID === 0) prevCart.splice(editID, 1)
        for(var i = 0; i < item.length; i++) prevCart.push(item[i])
        localStorage.setItem("ddCart", JSON.stringify(prevCart));
        let sub = 0
        for(var t = 0; t < prevCart.length; t++) sub += prevCart[t].price * prevCart[t].quantity
        setSubTotal(Math.round(sub * 100) / 100)
        setItems(prevCart)
    }, [items])

    const removeItem = useCallback((id) => {
        let newItems = []
        for(var i = 0; i < items.length; i++) {
            if(i !== id) newItems.push(items[i])
        }
        let sub = 0
        for(var t = 0; t < newItems.length; t++) sub += newItems[t].price * newItems[t].quantity
        localStorage.setItem("ddCart", JSON.stringify(newItems));
        setSubTotal(sub)
        setItems(newItems)
    }, [items])

    const setDiscount = useCallback((discountAmt) => {
        const discounted = Math.round(discountAmt * 100) / 100
        setDiscountState(discounted)
    }, [])
    
    const updateTax = useCallback(() => {
        if((shippingValues.state !== 'AZ' && shippingValues.state !== 'CA') || subTotal === 0) setTax(0)
        else {
            const apiAddress = process.env.REACT_APP_API_SERVER + '/web/order/salestax/'
            fetch(apiAddress, {
                method: "POST",
                headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
                body: JSON.stringify({
                    state: shippingValues.state,
                    city: shippingValues.city,
                    zip: shippingValues.zip,
                    subtotal: subTotal - discount
                })
            })
            .then(response => response.json())
            .then(data => {
                if(data.status === 200) {
                    const newTax = items.reduce((sum, item) => sum + (Math.round(item.price * data.payload.rate * 100) / 100) * item.quantity, 0)
                    setTax(newTax)
                    setTaxRate(data.payload.rate)
                }
                else setTax(0)
            })
            .catch(() => { setTax(0) })
        }
    }, [discount, shippingValues, subTotal, items])

    useEffect(() => {
        if(shippingValues) updateTax()
    }, [discount, shippingValues, updateTax])

    const getItemList = useCallback(() => {
        let itemList = []
        for(var i = 0; i < items.length; i++) {
            itemList.push({
                id: items[i].item,
                name: `${items[i].year} ${items[i].make} ${items[i].model} ${items[i].productType}`,
                quantity: items[i].quantity,
                unit_amount: {
                    currency_code: 'USD',
                    value: Math.round(items[i].price * 100) / 100
                },
                tax: {
                    currency_code: 'USD',
                    value: Math.round(items[i].price * taxRate * 100) / 100
                }
            })
        }
        return itemList
    }, [items, taxRate])

    const emptyCart = useCallback((orderInfo) => {
        setSubTotal(0)
        setItems([])
        setTax(0)
        setDiscount(0)
        if(orderInfo) {
            setContactValues()
            setShippingValues()
            setCompletedOrder(orderInfo)
        }
        localStorage.removeItem("ddCart")
    }, [setDiscount])

    function getShippingMethod(state, address) {
        if(state === 'HI' || state === 'AK') return 'USPS Priority Mail'
        const editAdd = address.replace(/[^A-Za-z0-9]/g, "-")
        if(editAdd.toLowerCase().includes('po') && address.toLowerCase().includes('box'))
            return 'USPS Priority Mail'
        return "FedEx Ground Home Delivery"
    }

    function getDeliveryDate(items) {
        let current
        for(var i = 0; i < items.length; i++) {
            const preDate = new Date(items[i].delivery)
            if(!current || preDate > current) current = preDate
        }
        return current.toISOString()
    }

    function getOptions(item) {
        if(item.fabric && item.color) return item.fabric + '-' + item.color
        if(item.color) return item.color
        return ''
    }

    const submitOrder = useCallback(async (paymentValues) => {
        let adjustedItems = []
        for(var i = 0; i < items.length; i++) {
            adjustedItems.push({
                name: items[i].productName + ' ' + items[i].productType,
                partNum: items[i].item,
                year: items[i].year,
                color: items[i].colorName || '',
                make: items[i].make,
                model: items[i].model,
                option: getOptions(items[i]),
                quantity: items[i].quantity,
                unitPrice: items[i].price,
                totalPrice: Math.round(items[i].price * items[i].quantity * 100) / 100,
                embroidery: false,
                image: items[i].image,
                row: items[i].row ? items[i].upgradedConsole ? 'CONSOLE' : items[i].row : ''
            })
        }
        
        const body = {
            contact: {
                email: contactValues.email
            },
            shipping: {
                name: contactValues.firstName + ' ' + contactValues.lastName,
                company: shippingValues.company,
                address: shippingValues.address1,
                address2: shippingValues.address2,
                city: shippingValues.city,
                state: shippingValues.state,
                zip: shippingValues.zip,
                country: "United States",
                phone: contactValues.phone,
                extension: contactValues.extension,
                shippingMethod: getShippingMethod(shippingValues.state, shippingValues.address1),
                deliveryDate: getDeliveryDate(items),
                residential: shippingValues.destination === 'Residential' ? true : false
            },
            payment: {
                paymentMethod: paymentValues.transactionID ? "paypal" : "credit",
                cardNumber: paymentValues.transactionID ? '' : paymentValues.ccnum,
                expiration: paymentValues.transactionID ? '' : paymentValues.expiration,
                securityCode: paymentValues.transactionID ? '' : paymentValues.cvv,
                transId: paymentValues.transactionID ? paymentValues.transactionID : '',
                amount: Math.round((subTotal + tax - discount) * 100) / 100
            },
            order: {
                subTotal: subTotal,
                tax: tax,
                shipping: 0.00,
                discount: discount,
                total: Math.round((subTotal + tax - discount) * 100) / 100,
                items: [...adjustedItems]
            },
            other: {
                site: products.config.website,
                siteURL: products.config.websiteURL,
                sitePhone: products.config.contactPhone,
                siteEmail: products.config.contactEmail,
                reference: contactValues.reference,
                orderNumber: paymentValues.fpNumber || '',
                soNumber: paymentValues.soNumber || ''
            }
        }
        
        const apiAddress = process.env.REACT_APP_API_SERVER + '/web/order/retail/'
        const sendIt = await fetch(apiAddress, {
            method: "POST",
            headers: { "Content-Type": "application/json", authentication: process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
            body: JSON.stringify(body)
        }).then((response) => {
            if(response.status && response.status === 300) return { response: 300, payload: 'Credit Card Declined. Please try again.'}
            if(!response.ok) return { response: 500, payload: 'Network Error' }
            return response.json()
        }).then((data) => { 
            if(data.status === 200) return { response: 200, payload: data.payload }
            else return { response: 400, payload: data.payload }
        }).catch((error) => {
            return { response: 500, payload: error }
        });

        if(sendIt.response === 200) {
            let orderInfo = {...body}
            orderInfo.other.orderNumber = sendIt.payload
            emptyCart(orderInfo)
        }
        
        return sendIt
    }, [items, contactValues, shippingValues, subTotal, tax, discount, emptyCart, products.config])

    const memoizedValue = useMemo(
        () => ({
            items: items,
            subTotal: subTotal,
            tax: tax,
            discount: discount,
            setDiscount: setDiscount,
            setShippingValues: setShippingValues,
            setContactValues: setContactValues,
            addItem: addItem,
            removeItem: removeItem,
            emptyCart: emptyCart,
            getItemList: getItemList,
            hover: hover,
            setHover: setHover,
            submitOrder: submitOrder,
            completedOrder: completedOrder
        }), [items, subTotal, tax,  discount, setDiscount, setShippingValues, setContactValues, addItem, removeItem, emptyCart, 
            getItemList, hover, setHover, submitOrder, completedOrder]
    );
  
    return <CartContext.Provider value={memoizedValue}>{children}</CartContext.Provider>;
}