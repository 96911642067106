import { Link } from "react-router-dom"
import { CategoryStyle } from "../style"

export default function Category({category}) {
    const style = CategoryStyle()

    return(
        <Link to={`./${category.id}`} style={style.container} className='component-hover category'>
            <div style={style.imageContainer} className="image-expand">
                <img style={style.image} alt={category.name} src={category.categoryImage} />
            </div>
            <div style={style.textContainer}>
                <div style={style.header}>{category.name}</div>
                <div style={style.subText}>{category.metaDescription}</div>
            </div>
        </Link>
    )
}