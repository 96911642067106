import { useContext } from "react"
import { ProductsContext } from "../../providers/productsProvider"

export function FullImageStyle() {
    const theme = useContext(ProductsContext).theme
    
    return {
        background: {
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            overflowY: "hidden",
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, .9)",
            display: "flex",
            justifyContent: "center",
            zIndex: "200"
        },
        container: {
            position: "relative",
            zIndex: "100",
            width: "100%",
            maxWidth: "1024px",
            height: "100vh",
            display: "flex",
            backgroundColor: "transparent", 
            flexDirection: "column",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center"
        },
        preview: {
            backgroundColor: theme.previewImageBackground,
            width: "94vw",
            height: "94vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        previewImage: {
            maxWidth: "100%",
            maxHeight: "100%"
        },
        circleContainer: {
            display: "flex",
            width: "100%",
            gap: "14px",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px 14px"
        },
        littleCircle: {
            width: "8px",
            height: "8px",
            backgroundColor: "#dddddd",
            borderRadius: "4px"
        },
        bigCircle: {
            width: "16px",
            height: "16px",
            backgroundColor: "#ffffff",
            borderRadius: "8px"
        },
        theX: {
            position: "absolute",
            top: "24px",
            right: "24px",
            fontSize: "36px",
            fontWeight: "700",
            color: "#ffffff"
        }
    }
}