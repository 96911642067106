import { Carousel } from "./components/carousel";
import { PreviewImage } from "./components/preview";
import { PreviewStyle } from "../../style";

export default function Preview({ product, precart, theme }) {
    const style = PreviewStyle()

    function getImageSet() {
        let imageArray = []
        if(product.options && product.options.length > 0) {
            for(var i = 0; i < product.options[precart.colorOption].images.length; i++) 
                imageArray.push(product.options[precart.colorOption].images[i])
        }
        for(var j = 0; j < product.images.length; j++) imageArray.push(product.images[j])
        return imageArray
    }

    const imageSet = getImageSet()

    return (
        <div style={style.previewContainer} id="image-top">
            <Carousel imageSet={imageSet} precart={precart} theme={theme} />
            <PreviewImage image={imageSet[precart.imageSelection]} precart={precart} />
        </div>
    );
  }
  