import { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { ProductsContext } from "../../providers/productsProvider"
import { TOCStyle } from "../style"
import Announcement from "./announcement"

export function TOC({faq, contact, about}) {
    const style = TOCStyle()
    const products = useContext(ProductsContext)
    const { lineID, productID } = useParams()

    const lineData = products.categories.filter((p) => p.id === lineID)[0]
    const productData = productID ? lineData.products.filter((p) => p.id === productID)[0] : null
        
    return(
        <>
            { products.config.announcement && products.config.announcement !== "" && <Announcement message={products.config.announcement} />}
            <div style={style.container}>
                { lineID || faq || contact || about ? 
                    <><Link style={style.link} to={'/products/'} className="page-hover">All Products</Link><div>&nbsp;&nbsp;/&nbsp;&nbsp;</div></>
                    : <div style={style.link}>All Products</div>
                }
                { lineID ?
                    productID ?
                        <><Link style={style.link} to={`/products/${lineID}/`} className="page-hover">{lineData.name}</Link><div>&nbsp;&nbsp;/&nbsp;&nbsp;</div></>
                        : <div style={style.link}>{lineData.name}</div>
                    : null
                }
                { productID && <div style={style.link}>{productData.name}</div> }
                { (faq || contact || about) && <div style={style.link}>{faq ? 'FAQ' : contact ? 'Contact' : 'About'}</div> }
            </div>
        </>
    )
}