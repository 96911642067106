import { FontContext } from "../../providers/fontProvider"
import { ProductsContext } from "../../providers/productsProvider"
import { useContext } from "react"

export function OuterStyle(){ 
    return {
        container: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "4vw",
            padding: "28px 0"
        }
    }
}

export function ContactStyle() { 
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme
    
    return {
        container: {
            display: fonts.mobile ? "none" : "flex",
            flexDirection: "column",
            width: "280px",
            gap: "28px"
        },
        inner: {
            display: "flex",
            flexDirection: "column",
            gap: "10px"
        },
        title: {
            ...fonts.large,
            color: theme.pageHeader
        },
        line: {
            width: "100%",
            height: "3px",
            backgroundColor: theme.pageText
        },
        expContainer: {
            display: "flex",
            gap: "16px",
            padding: "4px 8px"
        },
        expHeader: {
            ...fonts.h4,
            fontWeight: "500",
            color: theme.pageHeader,
            transitionDuration: ".3s"
        },
        link: {
            textDecoration: "none",
            color: theme.pageText
        },
        expText: {
            ...fonts.small,
            color: theme.pageText,
            transitionDuration: ".3s"
        },
        icon: {
            height: "36px",
            width: "36px",
            minWidth: "36px",
            fill: theme.pageHeader
        }
    }
}

export function FaqStyle() { 
    return {
        container: {
            width: "100%",
            maxWidth: "850px"
        }
    }
}

export function TocStyle() { 
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme
    
    return {
        container: {
            display: "flex",
            gap: "40px",
            border: fonts.mobile ? "0" : `1px solid ${theme.pageText}`,
            padding: fonts.mobile ? "28px 24px" : "48px 40px",
            flexWrap: "wrap",
            justifyContent: "center"
        },
        tocTitle: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '24px',
            color: theme.pageHeader
        },
        section: {
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: fonts.mobile ? "calc(50% - 20px)" : "calc(33% - 40px)"
        },
        title: {
            ...fonts.large,
            fontWeight: "600",
            textTransform: "uppercase",
            color: theme.pageHeader,
            paddingBottom: "8px",
            borderBottom: `1px solid ${theme.pageText}`,
            marginBottom: "8px"
        },
        link: {
            fontSize: fonts.mobile ? "14px" : "16px",
            fontWeight: "400",
            lineHeight: "20px",
            color: theme.pageText,
            cursor: "pointer",
            transitionDuration: ".3s"
        }
    }
}

export function QuestionsStyle() { 
    const fonts = useContext(FontContext)
    const theme = useContext(ProductsContext).theme
    
    return {
        container: {
            width: "100%",
            padding: "12px 0"
        },
        individual: {
            display: "flex",
            flexDirection: "column",
            padding: "48px 24px",
        },
        title: {
            ...fonts.h1,
            color: theme.pageHeader,
            textTransform: "uppercase",
            fontWeight: "700"
        },
        categoryContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
            padding: "24px 0"
        },
        category: {
            ...fonts.h2,
            color: theme.pageHeader
        },
        question: {
            ...fonts.h3,
            color: theme.pageHeader
        },
        answer: {
            ...fonts.standard,
            color: theme.pageText
        }
    }
}