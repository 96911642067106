import { useContext } from "react"
import { CartContext } from "../../providers/cartProvider"
import { ReactComponent as EditIcon } from '../../icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg'
import { Link, useNavigate } from "react-router-dom"
import { CartPanelStyle } from "../style"
import { ProductsContext } from "../../providers/productsProvider"

export function CartPanel({ hover, setHover }) {
    const navigate = useNavigate()
    const style = CartPanelStyle()
    const products = useContext(ProductsContext)
    const cart = useContext(CartContext)
    const theme = products.theme

    const removeHover = () => {
        if(hover) setHover(false)
    }

    function editIconClick(product, id) {
        navigate(`/products/${product.category}/${product.product}?a=${product.colorID}&e=${id}`)
    }
    
    return (
        <div style={style.container} className={hover ? "cart-panel hovered" : "cart-panel"} onMouseLeave={() => removeHover()}>
            <div style={style.inner}>
                <div style={style.title}>Shopping Cart</div>
                <div>
                    { cart.items.length === 0 && <div style={style.noItems}>
                        There are no items in your Shopping Cart
                    </div> }
                    { cart.items.length > 0 && cart.items.map((prod, id) => {
                        return <div key={id} style={{...style.itemContainer, borderBottom: id === cart.items.length - 1 ? "none" : `1px solid ${theme.pageText}`}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "18px"}}>
                                { !prod.upgradedConsole && <EditIcon style={style.delete} id={id} className={'header-hover'} onClick={() => editIconClick(prod, id)} /> }
                                <DeleteIcon style={style.delete} id={id} className={'header-hover'} onClick={() => cart.removeItem(id)} />
                            </div>
                            <div style={style.detailsContainer}>
                                <div style={style.name}>{prod.productName} {prod.productType}</div>
                                { prod.year && prod.make && prod.model && <div style={style.details}>{prod.year} {prod.make} {prod.model}</div> }
                                { prod.colorName && <div style={style.details}>Color: {prod.colorName}</div> }
                                { prod.row && <div style={style.details}>Row: {prod.row}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quantity: {prod.quantity}</div> }
                                { !prod.row && <div style={style.details}>Quantity: {prod.quantity}</div> }
                            </div>
                            <div style={style.cost}>${(prod.quantity * prod.price).toFixed(2)}</div>
                        </div>
                    })}
                </div>
                <Link to={'/shopping-cart/'} style={style.button} className={'button-hover'}>Go to Shopping Cart</Link>
            </div>
        </div>
    )
}