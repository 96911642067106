import { useContext, useEffect, useState } from "react"
import { ProductsContext } from "../../providers/productsProvider"

export function StarRating({rating, specify, isSmall = false}) {
    const products = useContext(ProductsContext)
    const theme = products.theme
    const size = isSmall ? window.innerWidth <= 500 ? "14px" : "18px" : "28px"
    const color1 = theme.specialBackground
    const [starsResult, setStarsResult] = useState()
    
    useEffect(() => {
        let stars = []
        for(var i = 0; i < 5; i++) {
            let remainder = rating - i
            if(remainder < 0) remainder = 0
            if(remainder > 1) remainder = 1
            stars.push(getStar(remainder, `${i}${specify}`))
        }
        setStarsResult(stars)
        // eslint-disable-next-line
    }, [rating])
    
    function getStar(val, id) {
        const percent = Math.floor(val * 100)
        
        return <svg key={id} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" aria-hidden="true" focusable="false">
            <polygon 
                points="25 9.12 15.5669599 9.12 12.512219 0 9.40860215 9.12 0 9.12 7.55131965 14.856 4.47214076 24 12.512219 18.216 20.5522972 24 17.4731183 14.856" 
                style={{fill: `url(#starfill${id})`}}>
            </polygon>
            <defs>
                <linearGradient id={`starfill${id}`}>
                    <stop offset="0%" style={{stopColor: color1}}></stop>
                    <stop offset={`${percent}%`} style={{stopColor: color1}}></stop>
                    <stop offset={`${percent + 1}%`} style={{stopColor: '#333333'}}></stop>
                    <stop offset="100%" style={{stopColor: "#333333"}}></stop>
                </linearGradient>
            </defs>
        </svg>
    }
    
    return (
        starsResult && <div style={{display: "flex", gap: isSmall ? "4px" : "6px", alignItems: "center"}}>
            { starsResult }
        </div>
    )
};