import { Link, useParams } from "react-router-dom";
import { HeaderStyle } from "../../style";
import { useContext } from "react";
import { ProductsContext } from "../../../providers/productsProvider";

export default function Logo({ landingData }) {
    const style = HeaderStyle(landingData)
    const products = useContext(ProductsContext)
    const {categoriesID, lineID, productID} = useParams()
    
    return (
        <Link to={(categoriesID && categoriesID !== 'products') || (lineID && products.categories.length > 1) || productID ? '/products/' : '/'} style={style.logoContainer}>
            <img height={window.innerWidth < 780 ? '40px' : '60px'} 
                width="auto" 
                alt={`${products.config.website} Logo`} 
                title={`${products.config.website} Logo`} 
                loading="eager" 
                src={landingData ? landingData.header.logoImage : products.config.headerLogo} 
            />
        </Link>
    );
}