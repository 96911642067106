import { useContext, useEffect } from "react";
import { Header } from "../../components/header";
import { TOC } from "../../components/toc";
import { LineTitle } from "./components/title";
import { Swatch } from "./components/swatch";
import { Footer } from "../../components/footer";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { ProductsContext } from "../../providers/productsProvider";
import { SwatchStyle } from "./style";

export default function LinePage() {
    const navigate = useNavigate()
    const style = SwatchStyle()
    const {lineID} = useParams()
    const products = useContext(ProductsContext)
    const lineData = products.categories.filter((p) => p.id === lineID)
    
    useEffect(() => {
        if(lineData && lineData.length > 0 && lineData[0].products.length === 1) {
            navigate('/products/' + lineID + '/' + lineData.products[0].id + '/', { replace: true })
        }
    }, [navigate, lineData, lineID])
    
    return (
        <>
            <Helmet>
                <title>{lineData[0].name} - {products.config.website}</title>
                <meta name="description" content={lineData[0].metaDescription} />
                <link rel="canonical" href={products.config.websiteURL + '/products/' + lineID} />
            </Helmet>
            <Header />
            <TOC />
            <LineTitle title={lineData[0].categoryHeader} />
            <div style={style.swatchContainer}>
                { lineData[0].products.map((product, id) => {
                    return <Swatch key={id} productDetails={product} specify={id} config={products.config} theme={products.theme} />
                })}
            </div>
            <Footer />
        </>
    );
}