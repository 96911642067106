import { useParams, Outlet, Navigate } from 'react-router-dom';
import LinePage from '../pages/line';
import { useContext } from 'react';
import { ProductsContext } from '../providers/productsProvider';

export default function LinePath() {
    const products = useContext(ProductsContext)
    const { lineID, productID } = useParams()

    const checkLine = () => {
        const line = products.categories.filter((d) => d.id === lineID)       
        if(line && line.length > 0) return <LinePage />
        return <Navigate to={'/products/'} />
    }
    
    return (
        productID ? <Outlet /> : checkLine()
    );
}