import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Header } from '../../components/header';
import { TOC } from '../../components/toc';
import { Footer } from '../../components/footer';
import Category from './components/category';
import { CategoryTitle } from './components/title';
import { OuterStyle } from './style';
import { ProductsContext } from '../../providers/productsProvider';

export default function CategoriesPage() {
    const navigate = useNavigate()
    const style = OuterStyle()
    const products = useContext(ProductsContext)
    const [titles, setTitles] = useState()

    useEffect(() => {
        if(products.config.categories.length === 1) navigate('/products/' + products.config.categories[0] + '/', { replace: true })
        else if(products.config.categories.length > 3) {
            let tempTitles = []
            for(var i = 0; i < products.categories.length; i++) {
                if(!tempTitles.includes(products.categories[i].name))
                    tempTitles.push(products.categories[i].name)
            }
           setTitles(tempTitles)
        }
    }, [navigate, products, setTitles])

    const getContentDescription = () => {
        let text = 'Highest Quality '
        for(var i = 0; i < products.config.categories.length; i++) {
            if(i === products.config.categories.length - 1) text += ' and ' + products.categories[i].name
            else if(i === products.config.categories.length - 2) text += products.categories[i].name
            else text += products.categories[i].name + ', '
        }
        text += ' by ' + products.config.website
        return text
    }
    
    return (
        <>
            <Helmet>
                <title>{products.config.website} - Choose a Product</title>
                <meta name="description" content={getContentDescription()} />
                <link rel="canonical" href={products.config.websiteURL + '/products'} />
            </Helmet>
            <Header />
            <TOC />
            <CategoryTitle />
            <div style={style.outer}>
                { products.categories.length < 4 ?
                    <div style={style.container}>
                        <div style={style.inner}>
                            { products.categories.map((category, id) => {
                                return <Category key={id} category={category} />
                            })}
                        </div>
                    </div>
                : titles && <>
                    { titles.map((title, id) => {
                        return <div key={id} style={style.individualContainer}>
                            <CategoryTitle individual={title} />
                            <div style={style.inner}>
                                { products.categories.map((category, id) => {
                                    return category.name === title ? <Category key={id} category={category} /> : null
                                })}
                            </div>
                        </div>
                    })}
                </> }
            </div>
            <Footer />
        </>
    );
}