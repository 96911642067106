import { ShopperApprovedStyle } from "../../style"

export default function ShopperApproved({ landingData }) {
    const style = ShopperApprovedStyle(landingData)
    
    return(
        <div style={style.container}>
            <div style={style.inner}>
                <div style={style.textContainer} id="customer-reviews">
                    <div style={style.thin}>Verified</div>
                    <div style={style.bold}>Reviews</div>
                    { window.innerWidth > 500 && <div style={{...style.line, flex:" 1 0"}} /> }
                </div>
                <div id="SA_wrapper_default" className="SA__wrapper"></div>
            </div>
        </div>
    )
}