import { useContext, useRef, useState } from "react"
import { CartContext } from "../../../providers/cartProvider"
import { SummaryStyle } from "../style"
import { FontContext } from "../../../providers/fontProvider"
import { ProductsContext } from "../../../providers/productsProvider"

export function CheckoutSummary() {
    const style = SummaryStyle()
    const products = useContext(ProductsContext)
    const cart = useContext(CartContext)
    const fonts = useContext(FontContext)
    const theme = products.theme
    const [loading, setLoading] = useState(false)
    const [showDiscount, setShowDiscount] = useState(false)
    const discountRef = useRef()
    const [discountMessage, setDiscountMessage] = useState()

    const checkDiscount = async (event) => {
        event.preventDefault()
        const code = discountRef.current.value
        if(code === '') {
            setDiscountMessage("Code can't be blank")
            return
        }
        setLoading(true)

        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
            body: JSON.stringify({code: code, cart: cart.items, siteDiscount: products.config.wholeSiteDiscountRate})
        }
        const apiAddress = process.env.REACT_APP_API_SERVER + '/web/site/discount/'
        
        await fetch(apiAddress, options)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if(data.payload.itemsDiscounted > 0) cart.setDiscount(data.payload.discount)
                else cart.setDiscount(0)
                setDiscountMessage(data.payload.message)
            })
            .catch((err) => {
                cart.setDiscount(0)
                setDiscountMessage('Network Error')
            })
        setLoading(false)
    }

    const removeDiscount = () => {
        cart.setDiscount(0)
        setDiscountMessage()
    }
    
    return (
        <div style={style.container}>
            { !fonts.mobile && <h1 style={style.title}>Summary</h1> }
            <div style={style.allLines}>
                <div style={style.lineContainer}><div>Subtotal</div><div>$ {cart.subTotal.toFixed(2)}</div></div>
                <div style={style.lineContainer}><div>Shipping</div><div>FREE</div></div>
                <div style={style.lineContainer}>
                    <div>{cart.tax > 0 ? 'Tax' : 'Estimated Tax'}</div>
                    <div>{cart.tax > 0 ? '$ ' + cart.tax.toFixed(2) : '---'}</div>
                </div>
                <div style={style.lineContainer}>
                    <div style={{color: cart.discount > 0 ? theme.specialBackground : '', fontWeight: cart.discount > 0 ? '500' : '400'}}>Discounts</div>
                    <div style={{color: cart.discount > 0 ? theme.specialBackground : '', fontWeight: cart.discount > 0 ? '500' : '400'}}>
                        {cart.discount > 0 ? `- $ ${cart.discount.toFixed(2)}` : '$ 0.00'}
                    </div>
                </div>
                <div style={style.blankLine} />
                <div style={{...style.lineContainer, fontWeight: "600"}}>
                    <div>Total</div>
                    <div>$ {cart.tax > 0 ? (cart.subTotal - cart.discount + cart.tax).toFixed(2) : (cart.subTotal - cart.discount).toFixed(2)}</div>
                </div>
                <div style={style.blankLine} />
                { fonts.mobile && !showDiscount && <div style={style.showDiscount} onClick={() => setShowDiscount(true)}>Discount Code?</div> }
                { (!fonts.mobile || showDiscount) && <form style={style.discountContainer}>
                    {cart.discount === 0 && <div style={style.discountTitle}>Discount Code?</div> }
                    {cart.discount === 0 && <div style={style.inputContainer}>
                        <input type="text" id='discount-input' aria-label='Discount Code' ref={discountRef} style={style.discountInput} />
                        <button disabled={loading} style={style.discountButton} className='secondary-button-hover' type="submit" onClick={checkDiscount}>
                            { loading ? <div className="lds-ring tiny"><div></div><div></div><div></div><div></div></div> : 'Apply' }
                        </button>
                    </div>}
                    <div style={{...style.discountMessage, color: cart.discount > 0 ? theme.specialBackground : theme.errorText}}>
                        {cart.discount === 0 && discountMessage && discountMessage !== '' ? '* ' : ''}{discountMessage}
                        {cart.discount > 0 && 
                            <div style={style.removeDiscount} className="remove-discount-hover" onClick={removeDiscount}>
                                {discountMessage ? 'Remove' : 'Remove Discount' }
                            </div>
                        }
                    </div>
                </form> }
            </div>
        </div>
    )
}