import { useState } from "react"
import { ContactFormStyle, QuestionsStyle } from "../style"

export function ContactUs({ sendContactEmail, messageSent, contactPhone }) {
    const style = QuestionsStyle()
    const contactStyle = ContactFormStyle()
    const [nameErr, setNameErr] = useState()
    const [emailErr, setEmailErr] = useState()
    const [messageErr, setMessageErr] = useState()

    const phoneMask = () => {
        const phone = document.getElementById('phone')
        const mask = "(xxx) xxx-xxxx"
        let current = phone.value
        current = current.replace(/\D+/g, '')
        
        let result = ''
        let counter = 0
        for(var i = 0; i < mask.length; i++) {
            if(counter >= current.length) break
            if(mask[i] === "x") {
                result += current[counter]
                counter++
            }
            else result += mask[i]
        }
        phone.value = result
    }

    const validateContact = () => {
        let good = true
        if(document.getElementById('name').value === '') { setNameErr('Required'); good = false }
        if(document.getElementById('email').value === '') { setEmailErr('Required'); good = false }
        else {
            const emailGood = String(document.getElementById('email').value).toLowerCase().match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if(!emailGood) { setEmailErr('Invalid Email Address'); good = false }
        }
        if(document.getElementById('message').value === '') { setMessageErr('Message cannot be blank'); good = false }
        
        if(good) sendContactEmail({
            name: document.getElementById('name').value,
            email: document.getElementById('email').value,
            phone: document.getElementById('phone').value,
            subject: document.getElementById('subject').value,
            message: document.getElementById('message').value
        })
    }
    
    return(
        <div style={style.contactContainer}>
            <h1 style={style.title}>Contact US</h1>
            <div style={style.categoryContainer}>
                <div style={style.category}>Interested in purchasing Seat Decor® Custom Fabric Auto Accessories?</div>
                <div>
                    <div style={style.question}>To find the dealer nearest you:</div>
                    <div style={style.answer}>Call us toll-free at: {contactPhone} or use our Dealer Locator</div>
                </div>
                <div style={style.category}>Interested in becoming a Seat Decor® reseller?</div>
                <div>
                    <div style={style.question}>Email: newdealer@dashdesigns.com</div>
                    <div style={style.answer}>Or, call toll free at {contactPhone} and Press “1” for the Sales Department.</div>
                </div>
                <div style={style.category}>Interested in employment at Seat Decor®?</div>
                <div>
                    <div style={style.question}>E-mail: newhire@dashdesigns.com</div>
                    <div style={style.answer}>Or, call toll free at {contactPhone} and Press “2” for Human Resources.</div>
                </div>
                <div style={style.category}>Returns and Warranty</div>
                <div>
                    <div style={style.answer}>Please vist our FAQ Page</div>
                </div>
                <div style={style.category}>Product or Order Questions</div>
                <div>
                    <div style={style.answer}>If you have any question please fill out the below form and a representative will contact you shortly.</div>
                </div>
                { !messageSent && <div style={contactStyle.form}>
                    <div style={contactStyle.inputContainer}>
                        <div style={contactStyle.label}>Name</div>
                        <input style={contactStyle.input} id='name' placeholder='' aria-label='Name' className={nameErr ? 'err' : ''} type="text" onFocus={() => setNameErr()} autoComplete="name" />
                        <div style={contactStyle.error}>{nameErr}</div>
                    </div>
                    <div style={contactStyle.inputContainer}>
                        <div style={contactStyle.label}>Email</div>
                        <input style={contactStyle.input} id='email' placeholder='' aria-label='Email' className={emailErr ? 'err' : ''} type="text" onFocus={() => setEmailErr()} autoComplete="email" />
                        <div style={contactStyle.error}>{emailErr}</div>
                    </div>
                    <div style={contactStyle.inputContainer}>
                        <div style={contactStyle.label}>Phone Number (optional)</div>
                        <input style={contactStyle.input} id='phone' placeholder='' aria-label='Phone' type="text" onChange={phoneMask} autoComplete="tel-national" />
                        <div style={contactStyle.error}></div>
                    </div>
                    <div style={contactStyle.inputContainer}>
                        <div style={contactStyle.label}>Subject</div>
                        <input style={contactStyle.input} id='subject' placeholder='' aria-label='Subject' type="text" autoComplete="off" />
                        <div style={contactStyle.error}></div>
                    </div>
                    <div style={contactStyle.inputContainer}>
                        <div style={contactStyle.label}>Message</div>
                        <textarea id="message" style={contactStyle.messageBox} className={messageErr ? 'err' : ''} onFocus={() => setMessageErr()} />
                        <div style={contactStyle.error}>{messageErr}</div>
                    </div>
                    <div style={contactStyle.button} className='button-hover' onClick={validateContact}>Continue</div>
                </div> }
                { messageSent !== '' && <div style={contactStyle.form}>
                    <div style={contactStyle.sentMessage}>{messageSent}</div>
                </div> }
            </div>
        </div>
    )
}