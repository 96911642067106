import React, { createContext, useState, useEffect } from 'react';
import NetworkError from '../components/networkError';
import { LoadingPage } from '../components/loading';

export const ProductsContext = createContext({});

export function ProductsProvider({ children }) {    
    const [data, setData] = useState()

    useEffect(() => {
        //const site = window.location.host.split('.')
        const options = {
            headers: {
                "Content-Type": "application/json", 
                "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10)
            }
        }

        //fetch('http://192.168.1.11/web/data/site/' + site[site.length - 2], options)
        fetch('https://api.dashdesigns.com/web/data/site/seatdecor', options) //Temporary. Use one above
            .then(response => response.json())
            .then(info => setData(info))
            .catch(err => console.log(err))
        
    }, [setData])
    
    if(!data) return <LoadingPage />

    console.log(data)

    return <ProductsContext.Provider value={data.payload}>
        { data.status === 200 ? children : <NetworkError /> }
    </ProductsContext.Provider>;
}