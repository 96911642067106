import { PreviewStyle } from "../../../style"

export function Carousel({ imageSet, precart, theme }) {
    const style = PreviewStyle()

    function imageChange(id) {
        if(id !== precart.imageSelection) {
            precart.setImageSelection(id)
            if(window.innerWidth < 780 && window.scrollY > 400) {
                document.getElementById('image-top').scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
    
    return(
        <div style={style.carousel} id="preview-carousel">
            { imageSet.map((img, id) => {
                return <button 
                    key={id}
                    style={{...style.previewButton, 
                        border: id === precart.imageSelection ? `2px solid ${theme.specialBackground}` : `2px solid ${theme.pageText}`}} 
                    onClick={() => imageChange(id)}
                >
                    <img style={style.carouselImage} height="auto" width="auto" alt={'Carousel Preview ' + id} title={'Carousel Preview ' + id} loading="eager" src={img} />
                </button>
            })}
        </div>
    )
}