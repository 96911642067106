import { FAQContact } from "./contact"
import { FAQ } from "./faq"
import { OuterStyle } from "../style"

export function FAQContainer({ products }) {
    const style = OuterStyle()
    
    return (
        <div style={style.container}>
            <FAQContact config={products.config} />
            <FAQ faqs={products.faq} />
        </div>
    )
}