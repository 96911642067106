import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"

export function AboutUsStyle(theme) {
    const fonts = useContext(FontContext)

    return {
        container: {
            width: "100%",
            padding: fonts.mobile ? "60px 4vw" : "100px 40px",
            scrollMarginTop: fonts.mobile ? "82px" : "92px",
            backgroundColor: theme.pageBackground, 
            color: theme.pageText
        },
        header: {
            ...fonts.h1,
            fontWeight: "300",
            width: "100%",
            textAlign: "center",
            paddingBottom: "40px",
            color: theme.pageHeader
        },
        inner: {
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            gap: "80px",
            justifyContent: "center"
        },
        infoContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: "100%",
            maxWidth: "500px"
        },
        littleHeader: {
            ...fonts.large,
            textAlign: fonts.mobile ? "center" : "left"
        },
        info: {
            ...fonts.standard,
            lineHeight: "24px",
            textAlign: fonts.mobile ? "center" : "left"
        },
        imageContainer: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "350px"
        }
    }
}