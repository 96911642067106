import { QuestionsStyle } from "../style"

export function Questions({faqs}) {
    const style = QuestionsStyle()

    return(
        <div style={style.container}>
            { faqs && faqs.map((title, id) => {
                return <div key={id} style={style.individual}>
                    <div style={style.title}>{title.title}</div>
                    { title.categories.map((category, key) => {
                        return <div key={key} style={style.categoryContainer} id={'scroll-' + id + key}>
                            <div style={style.category}>{category.category}</div>
                            { category.questions.map((question, keyid) => {
                                return <div key={keyid}>
                                    <div style={style.question}>{question.question}</div>
                                    <div key={key} style={style.answer}>
                                    { question.answer.split("\n").map((i, key) => {
                                        return <div key={key}>{i}<br /></div>
                                    })}
                                    </div>
                                </div>
                            })}
                        </div>
                    })}
                </div>
            })}
        </div>
    )
}