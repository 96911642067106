import { AnnouncementStyle } from "../style";

export default function Announcement({ message }) {
    const style = AnnouncementStyle()
    
    return (
        <div style={style.container}>
            { message }
        </div>
    )
}