import { ReactComponent as FaqIcon } from '../../../icons/faq.svg'
import { Link } from "react-router-dom";
import { HeaderStyle } from "../../style";

export default function Faq({ landingData }) {
    const style = HeaderStyle(landingData)
    
    return (
        <Link to={'/faq/'} style={style.iconContainer} className={landingData ? 'landing-header-hover' : 'header-hover'} aria-label='Frequently Asked Questions'>
            <FaqIcon style={style.icon} fill='none' />
            <div style={style.iconText}>FAQ</div>
        </Link>
    );
}