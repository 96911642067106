import { useState } from "react"
import { ContactSidePanel } from "./contactSide"
import { ContactUs } from "./contact"
import { OuterStyle } from "../style"

export function ContactUsContainer({ config }) {
    const style = OuterStyle()
    const [messageSent, setMessageSent] = useState('')

    async function sendContactEmail(form) {
        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
            body: JSON.stringify({text: 'Name: ' + form.name + '\nEmail: ' + form.email + '\nPhone: ' + form.phone + '\n\nSubject: ' + form.subject + '\n\nBody: ' + form.message})
        }

        const apiAddress = process.env.REACT_APP_API_SERVER + '/server/email'

        await fetch(apiAddress, options)
            .then(response => response.json())
            .then(data => {
                setMessageSent(data.payload)
            })        
    }
    
    return (
        <div style={style.container}>
            <ContactSidePanel config={config} />
            <ContactUs sendContactEmail={sendContactEmail} messageSent={messageSent} contactPhone={config.contactPhone}  />
        </div>
    )
}