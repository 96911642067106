import React, { useState, useContext } from 'react';
import Shipping from './shipping';
import { Payment } from './payment';
import { ContainerStyle } from '../style';
import { CartContext } from '../../../providers/cartProvider';
import Contact from './contact';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../components/errorPopup';

export default function Inputs({ setSendingOrder }) {
    const style = ContainerStyle()
    const cart = useContext(CartContext)
    const navigate = useNavigate()

    const [page, setPage] = useState(0)
    const [message, setMessage] = useState("")

    const movePastContact = (fields) => {
        cart.setContactValues(fields)
        setPage(1)
    }

    const movePastShipping = (fields) => {
        cart.setShippingValues(fields)
        setPage(2)
    }

    const submitOrder = async (fields, transaction = null) => {
        let paymentValues = fields
        if(transaction) {
            paymentValues.transactionID = transaction.transactionID
            paymentValues.fpNumber = transaction.fpNumber
            paymentValues.soNumber = transaction.soNumber
        }
        
        setSendingOrder(true)
        const orderSent = await cart.submitOrder(paymentValues)
        setSendingOrder(false)

        if(orderSent.response === 200) navigate('/completed')
        else setMessage(orderSent.payload.toString())
    }
    
    return (
        <div style={style.inputsContainer}>
            <Contact page={page} setPage={setPage} moveOn={movePastContact} />
            <Shipping page={page} setPage={setPage} moveOn={movePastShipping} />
            <Payment page={page} submitOrder={submitOrder} />
            {message && message !== "" && <ErrorPopup message={message} setMessage={setMessage} /> } 
        </div>  
    );
}