import { Link } from "react-router-dom"
import { SummaryStyle } from "../style"

export function CartSummary({cart}) {
    const style = SummaryStyle()
    
    return (
        <div style={style.container}>
            <div style={style.title}>Summary</div>
            <div style={style.allLines}>
                <div style={style.lineContainer}><div>Subtotal</div><div>$ {cart.subTotal.toFixed(2)}</div></div>
                <div style={style.lineContainer}><div>Shipping</div><div>FREE</div></div>
                <div style={style.lineContainer}><div>Estimated Tax</div><div>{cart.tax && cart.tax > 0 ? cart.tax.toFixed(2) : '---'}</div></div>
                <div style={style.lineContainer}><div>Discounts</div><div>{cart.discount && cart.discount > 0 ? '- $ ' + cart.discount.toFixed(2) : '$ 0.00'}</div></div>
                <div style={style.blankLine} />
                <div style={{...style.lineContainer, fontWeight: "600"}}><div>Total</div><div>$ {(cart.subTotal - cart.discount + cart.tax).toFixed(2)}</div></div>
                <div style={style.blankLine} />
            </div>
            { cart.items.length > 0 && <Link to='./checkout' id="gtm-checkout" style={style.checkout} className="button-hover">Checkout</Link> }
            { cart.items.length === 0 && <div style={style.noCheckout}>Checkout</div> }
            <Link to='./products' style={style.shopping} className="secondary-button-hover">
                { cart.items.length > 0 ? "Keep Shopping" : "Start Shopping" }
            </Link>
        </div>
    )
}