import { useState, useEffect } from "react"
import { LoadingPage } from "../loading"

export default function NetworkError() {
    const [pinger, setPinger] = useState(0)

    useEffect(() => {
        if(pinger < 3) {
            const apiAddress = process.env.REACT_APP_API_SERVER + '/ping'
            fetch(apiAddress)
            .then((response) => {
                if(response.ok) window.location.reload()
            })
            .catch(() => {
                setTimeout(() => {
                    setPinger(pinger + 1)
                }, 1000)
            })
        }
    }, [pinger, setPinger])

    const container = {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: window.innerWidth < 1030 ? 'column' : 'row-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        gap: window.innerWidth < 1030 ? '24px' : '60px'
    }

    const imageContainer = {
        display: window.innerWidth < 1030 ? 'none' : 'block',
        height: window.innerWidth < 1030 ? '300px' : '600px'
    }

    const image = {
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%'
    }

    const textContainer = {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        textAlign: window.innerWidth < 1030 ? 'center' : 'left',
    }

    const header = {
        fontSize: window.innerWidth < 1030 ? '28px' : '40px',
        fontWeight: '800'
    }

    const subHeader = {
        fontSize: window.innerWidth < 1030 ? '20px' : '24px',
        fontWeight: '500'
    }

    const text = {
        width: '100%',
        maxWidth: '500px',
        fontSize: window.innerWidth < 1030 ? '14px' : '18px',
        lineHeight: window.innerWidth < 1030 ?'20px' : '26px',
    }

    const button = {
        width: '100%',
        height: '50px',
        backgroundColor: '#6a789c',
        border: '2px solid #42527a',
        fontSize: '20px',
        fontWeight: '600',
        textTransform: 'uppercase',
        color: '#ffffff',
        cursor: 'pointer',
        margin: '12px 0',
        borderRadius: '12px'
    }
    
    return(
        pinger >= 3 ? <div role="alert" style={container}>
            <div style={imageContainer}>
                <img src='/img/other/error/network-error.webp' alt='network-error' style={image} />
            </div>
            <div style={textContainer}>
                <div style={header}>Website Unavailable</div>
                <div style={subHeader}>Don't worry, it won't take long...</div>
                <div style={text}>This usually means we are doing an update on the website, or the server is being refreshed. Generally this doesn't take longer than a couple of minutes.</div>
                <div style={text}>You can press the 'Try Again' button below to see if everything is back up, or place an order over the phone with one of our customer service representatives.</div>
                <button style={button} onClick={() => window.location.reload()}>Try again</button>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <div style={text}><b>CALL OUR CUSTOMER SERVICE</b></div>
                    <div style={subHeader}>480-967-7829</div>
                </div>
            </div>
        </div> : <LoadingPage />
    )
}